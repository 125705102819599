<template>
    <div id="apps">
        <div class="zucess clear">
            <img :src="$store.getters.userinfo.avatar" class="imgh"/>
            <div class="bigboc clear">
                <img src="../assets/success.png"/>
                <template v-if="$store.getters.userinfo.redirect == 3">
                    <p>恭喜您已经是</p>
                    <p>「猫着拼团」分享团长！</p>
                </template>
                <template v-if="$store.getters.userinfo.redirect != 3">
                    <p>欢迎加入猫着拼团</p>
                </template>
<!--                <p>&</p>-->
                <img class="erwem" src="../assets/erweima.jpg" style="width: 50%">
                <p>长按识别二维码，关注公众号</p>
            </div>
        </div>
    </div>
</template>
<script>
  import {userRq} from "@/api/user";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        data: {}
      }
    },
    methods: {
    },
    created() {
    }
  }
</script>
<style>
    .clear {
        clear: both;
        overflow: hidden;
    }

    * {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
    }

    .zucess {
        width: 100%;
        height: 100vh;
        background: -moz-linear-gradient(top, #E9554B 0%, #FA8F39 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #E9554B), color-stop(100%, #FA8F39));
        background: -webkit-linear-gradient(top, #E9554B 0%, #FA8F39 100%);
        background: -o-linear-gradient(top, #E9554B 0%, #FA8F39 100%);
        background: -ms-linear-gradient(top, #E9554B 0%, #FA8F39 100%);
        background: linear-gradient(to bottom, #E9554B 0%, #FA8F39 100%);
    }

    .erwem {
        /*width: 40%;*/
        /*height: 20vh;*/
        border: solid 1px #C7C7C5;
        margin: 5% auto;
    }

    .zucess .imgh {
        border-radius: 100%;
        width: 20%;
        border: solid 1px #fff;
        display: block;
        margin: 12% auto 8% auto;
    }

    .zucess .bigboc {
        width: 80%;
        margin: 0 auto;
        border-radius: 6px;
        background: #fff;
    }

    .zucess .bigboc img {
        width: 30px;
        display: block;
        margin: 6% auto;
    }

    .zucess .bigboc p {
        display: block;
        text-align: center;
        font-size: 12px;
        color: #626262;
        margin-bottom: 4%;
    }

    .zucess .bigboc p:nth-child(4) {
        color: #C7C7C5;
    }

    .zucess .bigboc p:nth-child(6) {
        font-size: 10px;
        margin-bottom: 5%;
    }
</style>
